import React from 'react';

export default function Logo() {
  return (
    <svg height="15" width="100">
    <text x="0" y="15" fill="#6e6e6e">&lt;&#47;</text>
      <text x="17" y="15" font-size="15px" fill="rgb(64, 96, 155)">SamV</text>
      <text x="60" y="15" fill="#6e6e6e">&gt;</text>
</svg>
  );
}